import { actionFlow } from '@resources/helpers/redux';
import { REPORTS_CONST } from '@resources/reports/constants';
import { repoActionLogs, repoGraphIncome } from '@resources/reports/repo';
import { ActionLogsPayload, GraphIncomePayload } from '@resources/reports/interfaces';

export const actionLogs = (payload: ActionLogsPayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        REPORTS_CONST.REPORTS_ACTION_LOGS_FETCH,
        REPORTS_CONST.REPORTS_ACTION_LOGS_SUCCESS,
        REPORTS_CONST.REPORTS_ACTION_LOGS_FAIL,
        async () =>
            await repoActionLogs({
                ...payload.pagination,
            }),
    );

export const graphOfficesIncomes = (payload: GraphIncomePayload) => (dispatch) =>
    actionFlow(dispatch, payload)(
        REPORTS_CONST.REPORTS_GRAPH_OFFICES_INCOMES_FETCH,
        REPORTS_CONST.REPORTS_GRAPH_OFFICES_INCOMES_SUCCESS,
        REPORTS_CONST.REPORTS_GRAPH_OFFICES_INCOMES_FAIL,
        async () => await repoGraphIncome(payload.filters),
    );
