import React from 'react';
import {
    Button,
    Checkbox,
    FormGroup,
    InputGroup,
    Intent,
    MenuItem,
    NumericInput,
} from '@blueprintjs/core';
import { Box, Flex } from 'reflexbox';

import * as styles from './index.module.scss';

import { DocsMultiSelectCom, docsMultiSelectItemRender } from '@components/docs-multi-select-com';
import { useRandomId } from '@components/ui/use-random-id';

import { Price } from '@resources/prices/_price';
import {
    issetArrayItem,
    removeArrayItemByIndex,
    swapArrayElements,
    toogleArrayItem,
} from '@resources/helpers/array';
import { DocTemplate } from '@resources/config/_doc-template';
import { FAMILY_STATUS, FAMILY_STATUS_LABEL } from '@resources/cards/_card';
import { Select } from '@components/ui/select';
import { IconNames } from '@blueprintjs/icons';

type Props = {
    price: Price;
    docsTemplates: DocTemplate[];
    onRemove: () => void;
    onUpdate: (price: Price) => void;
};

export const PriceItem = (props: Props) => {
    const [price, setPrice] = React.useState<Price>(props.price);
    const [docsId] = useRandomId('docs');

    return (
        <div className={styles.container}>
            <Flex>
                <Box width={['30%']}>
                    <FormGroup label="Короткое имя" style={{ marginRight: 10 }}>
                        <InputGroup
                            value={price.name_short}
                            placeholder="Анализ мочи"
                            onChange={(e) =>
                                setPrice({
                                    ...price,
                                    name_short: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['30%']}>
                    <FormGroup label="Имя" style={{ marginRight: 10 }}>
                        <InputGroup
                            value={price.name}
                            placeholder="Анализ мочи"
                            onChange={(e) =>
                                setPrice({
                                    ...price,
                                    name: e.target.value,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
                <Box width={['10%']}>
                    <FormGroup label="Цена" style={{ marginRight: 10 }}>
                        <NumericInput
                            value={price.price}
                            selectAllOnFocus={true}
                            placeholder="1000"
                            onValueChange={(value) =>
                                setPrice({
                                    ...price,
                                    price: value,
                                })
                            }
                        />
                    </FormGroup>
                </Box>
            </Flex>

            <Box width={['20%']} style={{ marginBottom: '10px' }}>
                <Select
                    value={''}
                    style={{ width: '100%' }}
                    items={props.docsTemplates.map((docTemplate) => ({
                        label: docTemplate.label,
                        value: docTemplate.name,
                    }))}
                    onChange={(value) => {
                        setPrice((prev) => ({
                            ...prev,
                            docs: [...prev.docs, value],
                        }));
                    }}
                    placeholderLabel="- Добавить документ -"
                />
            </Box>

            <Box width={['40%']}>
                {price.docs.map((doc, index) => (
                    <div key={index} className={styles.doc_item}>
                        <div>
                            {
                                props.docsTemplates.find((docTemplate) => docTemplate.name === doc)
                                    ?.label
                            }
                        </div>
                        <div className={styles.doc_item_buttons}>
                            <Button
                                small={true}
                                icon={IconNames.ARROW_UP}
                                disabled={!price.docs[index - 1]}
                                onClick={() => {
                                    if (price.docs[index - 1]) {
                                        setPrice((prev) => ({
                                            ...prev,
                                            docs: swapArrayElements(price.docs, index, index - 1),
                                        }));
                                    }
                                }}
                            />
                            <Button
                                small={true}
                                icon={IconNames.ARROW_DOWN}
                                disabled={!price.docs[index + 1]}
                                onClick={() => {
                                    if (price.docs[index + 1]) {
                                        setPrice((prev) => ({
                                            ...prev,
                                            docs: swapArrayElements(price.docs, index, index + 1),
                                        }));
                                    }
                                }}
                            />
                            <Button
                                intent={Intent.DANGER}
                                small={true}
                                text="Удалить"
                                onClick={() => {
                                    setPrice((prev) => ({
                                        ...prev,
                                        docs: removeArrayItemByIndex(prev.docs, index),
                                    }));
                                }}
                            />
                        </div>
                    </div>
                ))}
            </Box>

            <div className={styles.buttons}>
                <Button
                    icon="floppy-disk"
                    small={false}
                    style={{ marginRight: 10 }}
                    onClick={() => props.onUpdate(price)}>
                    Сохранить
                </Button>
                <Button icon="trash" small={false} onClick={props.onRemove}>
                    Удалить
                </Button>
            </div>
        </div>
    );
};
