import { getAccessToken } from '@resources/helpers/request';
import { useRef, useState } from 'react';
import axios from 'axios';

type Props = {
    onComplete: (filenames: string[]) => void;
    onError: (e: Error) => void;
};

export const useFilesUploader = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const upload = () => {
        setLoading(true);

        const data = new FormData();
        data.set('files[]', fileInputRef.current?.files[0]);

        axios
            .request({
                url: process.env.HOST_API + '/v1/files/',
                method: 'post',
                data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            })
            .then((res) => {
                props.onComplete(res.data.content.filenames);
            })
            .catch(props.onError)
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        upload,
        loading,
        fileInputRef,
    };
};
