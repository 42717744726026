import { Request } from '@resources/helpers/request';

import { RequestResponse } from '@resources/helpers/request';
import {
    ActionLogsReq,
    ActionLogsResp,
    GraphIncomeReq,
    GraphIncomeResp,
} from '@resources/reports/interfaces';

export function repoActionLogs(payload: ActionLogsReq): Promise<ActionLogsResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/reports/action-logs', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}

export function repoGraphIncome(payload: GraphIncomeReq): Promise<GraphIncomeResp> {
    return new Promise((resolve, reject) => {
        Request.get('/v1/reports/graph/income', payload)
            .then((response: RequestResponse<any>) => resolve(response.content))
            .catch(reject);
    });
}
