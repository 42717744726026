import * as React from 'react';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'reflexbox';
import moment from 'moment-timezone';
import { Button, Classes, Dialog, FormGroup, InputGroup, Intent, Switch } from '@blueprintjs/core';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

import { FormLabel } from '@components/ui/form-label';
import { PriceItem } from '@components/price-tem';
import { UserItem } from '@components/user-item';
import { Pagination } from '@components/ui/pagination';
import { ActionLogCom } from '@components/action-log-com';
import { AlertRemove } from '@components/alert-remove';
import { useAccountToaster } from '@components/use-account-toaster';

import { Layout } from '@containers/layout';

import { GlobalState } from '@resources/reducers';
import {
    selectIsAdmin,
    selectProfile,
    selectUserById,
    selectUsersViewer,
} from '@resources/users/selectors';
import { initialUser, User, USER_ROLE, USER_ROLE_LABEL } from '@resources/users/_user';
import { FORMAT_DATETIME } from '@resources/helpers/datetime';
import { initialPrice, Price } from '@resources/prices/_price';
import { ActionLog, initialActionLog } from '@resources/reports/_action-log';
import { selectPriceById, selectPrices } from '@resources/prices/selectors';
import { priceCreate, priceRemove, priceUpdate } from '@resources/prices/actions';
import { userCreate, userRemove, userUpdate } from '@resources/users/actions';
import { actionLogs } from '@resources/reports/actions';
import { selectActionLogs } from '@resources/reports/selectors';
import { Config } from '@resources/config/_config';
import { selectConfig, selectDocsTemplates } from '@resources/config/selectors';
import { configUpdate } from '@resources/config/actions';
import {
    ConfigActions,
    PricesActions,
    ReportsActions,
    UsersActions,
} from '@resources/actions/_state';
import {
    selectConfigActions,
    selectPricesActions,
    selectReportsActions,
    selectUsersActions,
} from '@resources/actions/selectors';
import { ReportsPag } from '@resources/pagination/_state';
import { selectReportsPag } from '@resources/pagination/selectors';
import { useQueryParam } from '@resources/helpers/router';
import { useRandomId } from '@components/ui/use-random-id';
import { DocTemplate } from '@resources/config/_doc-template';
import { initialOffice, Office } from '@resources/offices/_office';
import { selectOfficeById, selectOffices } from '@resources/offices/selectors';
import { OfficeItem } from '@components/office-item';
import { officeRemove, officeSave } from '@resources/offices/actions';
import { FilesUploader } from '@components/files-uploader/files-uploader';
import { FileView } from '@components/signature/signature-image';

type Props = {
    profile: User;
    users: User[];
    prices: Price[];
    actionLogs: ActionLog[];
    reportsPag: ReportsPag;
    isAdmin: boolean;
    config: Config;
    configActions: ConfigActions;
    reportsActions: ReportsActions;
    usersActions: UsersActions;
    pricesActions: PricesActions;
    docsTemplates: DocTemplate[];
    offices: Office[];
    profileOffice: Office;
};

export default connect(mapStateToProps)((props: Props) => {
    const dispatch = useDispatch();
    const enableSettings = useQueryParam('s');

    const [expressTestNumberId] = useRandomId('express_test_number');
    const [expressTestExpireId] = useRandomId('express_test_expire');
    const [appTokenId] = useRandomId('express_test_expire');

    const [actionLogShow, setActionLogShow] = React.useState<ActionLog>({ ...initialActionLog });
    const [expressTestNumber, setExpressTestNumber] = React.useState<string>(
        props.config.express_test_number,
    );
    const [expressTestExpire, setExpressTestExpire] = React.useState<string>(
        props.config.express_test_expire,
    );
    const [appToken, setAppToken] = React.useState<string>(props.profile.app_token);
    const [signature, setSignature] = React.useState<string>(props.profile.signature);

    const [removeUserId, setRemoveUserId] = React.useState<number>(0);
    const [removePriceId, setRemovePriceId] = React.useState<number>(0);

    const user = useSelector((state: GlobalState) => selectUserById(state, removeUserId));
    const price = useSelector((state: GlobalState) => selectPriceById(state, removePriceId));

    const onPriceCreate = () => dispatch(priceCreate({ price: { ...initialPrice } }));
    const onPriceUpdate = (p: Price) => dispatch(priceUpdate({ price: p }));
    const onPriceRemove = (priceId: number) => dispatch(priceRemove({ price_id: priceId }));

    const onUserCreate = () => dispatch(userCreate({ user: { ...initialUser } }));
    const onUserUpdate = (u: User) =>
        dispatch(userUpdate({ user: u, is_data: props.profile.role !== USER_ROLE.ADMIN }));
    const onUserRemove = (userId: number) => dispatch(userRemove({ user_id: userId }));

    const onOfficeCreate = () => dispatch(officeSave({ office: { ...initialOffice } }));
    const onOfficeSave = (office: Office) => dispatch(officeSave({ office }));
    const onOfficeRemove = (officeId: number) => {
        if (
            confirm('Удалить офис?') &&
            confirm(
                'УДАЛЕННЫЙ ОФИС НЕВОЗМОЖНО БУДЕТ ВОССТАНОВИТЬ. БУДЕТ УДАЛЕНА ВСЯ СТАТИСТИКА.',
            ) &&
            confirm('Последнее предупреждение')
        ) {
            dispatch(officeRemove({ office_id: officeId }));
        }
    };

    useAccountToaster({
        usersActions: props.usersActions,
        pricesActions: props.pricesActions,
        configActions: props.configActions,
    });

    useEffect(() => {
        setAppToken(props.profile.app_token);
        setSignature(props.profile.signature);
        setExpressTestExpire(props.config.express_test_expire);
        setExpressTestNumber(props.config.express_test_number);
    }, [props.profile, props.config]);

    const onPage = (page: number) => {
        dispatch(
            actionLogs({
                pagination: {
                    ...props.reportsPag.action_logs,
                    page,
                },
            }),
        );
    };

    const onSaveExpressTest = () => {
        dispatch(
            configUpdate({
                config: {
                    ...props.config,
                    express_test_number: expressTestNumber,
                    express_test_expire: expressTestExpire,
                },
            }),
        );
    };

    React.useEffect(() => {
        dispatch(
            actionLogs({
                pagination: props.reportsPag.action_logs,
            }),
        );
    }, []);

    return (
        <Layout
            isLoading={
                props.configActions.config.loading ||
                props.configActions.config_update.loading ||
                props.reportsActions.action_logs.loading ||
                props.usersActions.user_create.loading ||
                props.usersActions.user_update.loading ||
                props.usersActions.user_proflie.loading ||
                props.usersActions.users.loading
            }
            title="Аккаунт">
            <Flex mb={10} flexDirection="column">
                <FormLabel>Ваши данные:</FormLabel>
                <Box mb={2}>Логин: {props.profile.login}</Box>
                <Box mb={2}>Имя: {props.profile.username}</Box>
                <Box mb={2}>Офис: {props.profileOffice.name}</Box>
                <Box mb={2}>Роль: {USER_ROLE_LABEL[props.profile.role]}</Box>
                <Box mb={2}>
                    Создан: {moment.utc(props.profile.created_at).local().format(FORMAT_DATETIME)}
                </Box>
                <Box mb={2}>
                    Обновлен: {moment.utc(props.profile.updated_at).local().format(FORMAT_DATETIME)}
                </Box>
            </Flex>

            <FormLabel>Наркомед.Подпись:</FormLabel>
            <Flex width={['30%']} flexDirection="column" mb={3}>
                <FormGroup label="Токен" labelFor={appTokenId} style={{ marginRight: 10 }}>
                    <InputGroup
                        value={appToken}
                        id={appTokenId}
                        placeholder="xxx-xxx-xxx"
                        onChange={(e) => {
                            setAppToken(e.currentTarget.value);
                        }}
                    />
                </FormGroup>

                <FormGroup
                    label="Подпись аккаунта"
                    labelFor={appTokenId}
                    style={{ marginRight: 10 }}>
                    {!!signature && <FileView uuid={signature} />}
                    <FilesUploader
                        label="Выберите изображение подписи"
                        onUploaded={(filenames) => {
                            if (filenames.length) {
                                setSignature(filenames[0]);
                            }
                        }}
                    />
                </FormGroup>

                <Button
                    intent={Intent.PRIMARY}
                    icon="saved"
                    onClick={() => {
                        onUserUpdate({
                            ...props.profile,
                            app_token: appToken,
                            signature,
                        });
                    }}>
                    Сохранить
                </Button>
            </Flex>

            <FormLabel>Экспресс тест:</FormLabel>
            <Flex width={['20%']} flexDirection="column" mb={3}>
                <FormGroup
                    label="Серия и номер"
                    labelFor={expressTestExpireId}
                    style={{ marginRight: 10 }}>
                    <InputGroup
                        value={expressTestNumber}
                        id={expressTestExpireId}
                        placeholder="М12Р10020721"
                        autoComplete={expressTestExpireId}
                        onChange={(e) => {
                            setExpressTestNumber(e.currentTarget.value);
                        }}
                    />
                </FormGroup>
                <FormGroup
                    label="Дата истечения срока"
                    labelFor={expressTestNumberId}
                    style={{ marginRight: 10 }}>
                    <InputGroup
                        value={expressTestExpire}
                        id={expressTestNumberId}
                        placeholder="09.2023"
                        autoComplete={expressTestNumberId}
                        onChange={(e) => setExpressTestExpire(e.currentTarget.value)}
                    />
                </FormGroup>
                <Button intent={Intent.PRIMARY} icon="saved" onClick={onSaveExpressTest}>
                    Сохранить
                </Button>
            </Flex>

            {props.isAdmin && (
                <React.Fragment>
                    {enableSettings && (
                        <Flex mb={30} flexDirection="column">
                            <FormLabel>Настройки:</FormLabel>
                            <Switch
                                checked={props.config.cash_visits_hide}
                                label="Скрыть цены посещений оплаченных наличым методом"
                                onChange={(e) => {
                                    dispatch(
                                        configUpdate({
                                            config: {
                                                ...props.config,
                                                cash_visits_hide: e.currentTarget.checked,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </Flex>
                    )}

                    <Flex flexDirection="column" mb={30}>
                        <FormLabel>История действий</FormLabel>
                        {props.actionLogs.map((actionLog) => (
                            <ActionLogCom
                                key={actionLog.id}
                                actionLog={actionLog}
                                onDataShow={() => setActionLogShow(actionLog)}
                            />
                        ))}
                        <Pagination pag={props.reportsPag.action_logs} onPage={onPage} />
                    </Flex>

                    <Dialog
                        isOpen={actionLogShow.id !== 0}
                        canOutsideClickClose={true}
                        usePortal={true}
                        onClose={() => setActionLogShow({ ...initialActionLog })}
                        style={{ width: 1000 }}>
                        <div className={Classes.DIALOG_HEADER}>Просмотр изменений</div>
                        <div className={Classes.DIALOG_BODY}>
                            <ReactDiffViewer
                                oldValue={JSON.stringify(actionLogShow.data_before, undefined, 4)}
                                newValue={JSON.stringify(actionLogShow.data_after, undefined, 4)}
                                compareMethod={DiffMethod.CHARS}
                            />
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Box display="flex" alignItems="center" className={Classes.UI_TEXT}>
                                    Красным отмечено то что удалено, зеленым то что добавлено
                                </Box>
                                <Button onClick={() => setActionLogShow({ ...initialActionLog })}>
                                    Закрыть
                                </Button>
                            </div>
                        </div>
                    </Dialog>

                    <Flex flexDirection="column" mb={30}>
                        <FormLabel>Персонал</FormLabel>
                        <Box>
                            {props.users.map((user) => (
                                <UserItem
                                    key={user.id}
                                    offices={props.offices}
                                    user={user}
                                    onUpdate={(u) => onUserUpdate(u)}
                                    onRemove={() => setRemoveUserId(user.id)}
                                />
                            ))}
                        </Box>
                        <Box>
                            <Button intent={Intent.SUCCESS} icon="add" onClick={onUserCreate}>
                                Добавить персонал
                            </Button>
                        </Box>
                    </Flex>

                    {props.isAdmin && (
                        <Flex flexDirection="column" mb={30}>
                            <FormLabel>Офисы</FormLabel>
                            <Box>
                                {props.offices.map((office) => (
                                    <OfficeItem
                                        key={office.id}
                                        office={office}
                                        onUpdate={(o) => onOfficeSave(o)}
                                        onRemove={() => onOfficeRemove(office.id)}
                                    />
                                ))}
                            </Box>
                            <Box>
                                <Button intent={Intent.SUCCESS} icon="add" onClick={onOfficeCreate}>
                                    Добавить офис
                                </Button>
                            </Box>
                        </Flex>
                    )}

                    <Flex flexDirection="column">
                        <FormLabel>Цены</FormLabel>
                        <Box>
                            {props.prices.map((price) => (
                                <PriceItem
                                    key={price.id}
                                    price={price}
                                    onUpdate={onPriceUpdate}
                                    onRemove={() => setRemovePriceId(price.id)}
                                    docsTemplates={props.docsTemplates}
                                />
                            ))}
                        </Box>
                        <Box>
                            <Button intent={Intent.SUCCESS} icon="add" onClick={onPriceCreate}>
                                Добавить услугу
                            </Button>
                        </Box>
                    </Flex>

                    <AlertRemove
                        isOpen={removeUserId !== 0}
                        onClose={() => setRemoveUserId(0)}
                        onConfirm={() => onUserRemove(removeUserId)}>
                        Удалить пользователя <b>{user.username}</b>? При удалении его можно будет
                        восстановить только через базу данных
                    </AlertRemove>

                    <AlertRemove
                        isOpen={removePriceId !== 0}
                        onClose={() => setRemovePriceId(0)}
                        onConfirm={() => onPriceRemove(removePriceId)}>
                        Удалить услугу <b>{price.name}</b>? При удалении его можно будет
                        восстановить только через базу данных
                    </AlertRemove>
                </React.Fragment>
            )}
        </Layout>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        profile: selectProfile(state),
        users: selectUsersViewer(state),
        prices: selectPrices(state),
        actionLogs: selectActionLogs(state),
        reportsPag: selectReportsPag(state),
        isAdmin: selectIsAdmin(state),
        config: selectConfig(state),
        configActions: selectConfigActions(state),
        reportsActions: selectReportsActions(state),
        usersActions: selectUsersActions(state),
        pricesActions: selectPricesActions(state),
        docsTemplates: selectDocsTemplates(state),
        offices: selectOffices(state),
        profileOffice: selectOfficeById(state, state.users.profile.office_id),
    };
}
