import React, { useMemo } from 'react';
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { Flex, Box } from 'reflexbox';

import * as styles from './index.module.scss';

import { User } from '@resources/users/_user';
import { Office } from '@resources/offices/_office';
import { Select } from '@components/ui/select';

type Props = {
    user: User;
    offices: Office[];
    onRemove: () => void;
    onUpdate: (user: User) => void;
};

export const UserItem = (props: Props) => {
    const [user, setUser] = React.useState<User>(props.user);

    const officesOptions = useMemo(
        () =>
            props.offices.map((office) => ({
                label: office.name,
                value: String(office.id),
            })),
        [props.offices],
    );

    return (
        <div className={styles.container}>
            <div className={styles.number}>ID: {props.user.id}</div>
            <Flex width={['100%']}>
                <FormGroup label="Имя" style={{ marginRight: 10 }}>
                    <InputGroup
                        value={user.username}
                        placeholder="Лидия"
                        onChange={(e) =>
                            setUser({
                                ...user,
                                username: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup label="Логин" style={{ marginRight: 10 }}>
                    <InputGroup
                        value={user.login}
                        placeholder="lidya"
                        onChange={(e) =>
                            setUser({
                                ...user,
                                login: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup label="Пароль" style={{ marginRight: 10 }}>
                    <InputGroup
                        value={user.password}
                        placeholder="lydya100300"
                        style={{ marginRight: 10 }}
                        onChange={(e) =>
                            setUser({
                                ...user,
                                password: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup label="Офис" style={{ marginRight: 10 }}>
                    <Select
                        value={String(user.office_id)}
                        style={{ width: '100%' }}
                        items={officesOptions}
                        onChange={(value) => {
                            setUser((prev) => ({
                                ...prev,
                                office_id: !!value ? Number(value) : 0,
                            }));
                        }}
                    />
                </FormGroup>
                <div className={styles.buttons}>
                    <Button
                        icon="floppy-disk"
                        style={{ marginRight: 10 }}
                        onClick={() => props.onUpdate(user)}>
                        Сохранить
                    </Button>
                    <Button icon="trash" onClick={props.onRemove}>
                        Удалить
                    </Button>
                </div>
            </Flex>
        </div>
    );
};
