import { GlobalState } from '@resources/reducers';
import { ActionLog, initialActionLog } from '@resources/reports/_action-log';
import { GraphIncomeTotal, GraphOfficeIncome } from '@resources/reports/_graph';

export const selectActionLogs = (state: GlobalState): ActionLog[] => {
    return state.reports.action_logs_ids
        .map((actionLogId) => {
            const actionLog = state.reports.action_logs.find(
                (actionLog) => actionLog.id === actionLogId,
            );
            return actionLog ? actionLog : { ...initialActionLog };
        })
        .filter((actionLog) => actionLog.id !== 0);
};

export const selectGraphOfficesIncomes = (state: GlobalState): GraphOfficeIncome[] =>
    state.reports.graph_offices_incomes;

export const selectGraphIncomeTotal = (state: GlobalState): GraphIncomeTotal => {
    const { amounts } = selectGraphOfficesIncomes(state)[0].income;

    return {
        common: amounts.common.reduce((total, item) => item + total, 0),
        card: amounts.card.reduce((total, item) => item + total, 0),
        cash: amounts.cash.reduce((total, item) => item + total, 0),
    };
};
