import React from 'react';

import * as styles from './index.module.scss';

import { ActionLog } from '@resources/reports/_action-log';
import moment from 'moment-timezone';
import { FORMAT_DATETIME } from '@resources/helpers/datetime';

type Props = {
    actionLog: ActionLog;
    onDataShow: () => void;
};

export const ActionLogCom = (props: Props) => {
    return (
        <a
            href="#"
            className={styles.link}
            onClick={(e) => {
                e.preventDefault();
                props.onDataShow();
            }}>
            #{props.actionLog.id} /{' '}
            {moment.utc(props.actionLog.created_at).local().format(FORMAT_DATETIME)}{' '}
            <b>{props.actionLog.name}</b>
        </a>
    );
};
