import React from 'react';
type SelectItem = {
    label: string;
    value: string;
};

type Props = {
    value: string;
    items: SelectItem[];
    placeholderLabel?: string;
    onChange: (value: string) => void;
    style?: React.CSSProperties;
};

export const Select = (props: Props) => {
    return (
        <div className="bp3-html-select" style={props.style}>
            <select
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value);
                }}>
                {!!props.placeholderLabel && <option value="">{props.placeholderLabel}</option>}
                {props.items.map((item, index) => (
                    <option key={String(index)} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            <span className="bp3-icon bp3-icon-double-caret-vertical" />
        </div>
    );
};
