import React from 'react';
import { Intent } from '@blueprintjs/core';

import { AppToaster } from '@components/ui/toaster';
import { useCanNav } from '@components/ui/use-can-nav';

import { ConfigActions, PricesActions, UsersActions } from '@resources/actions/_state';

type Props = {
    usersActions: UsersActions;
    pricesActions: PricesActions;
    configActions: ConfigActions;
};

export const useAccountToaster = (props: Props) => {
    useCanNav(props.usersActions.user_create.success, () => {
        AppToaster.show({
            message: 'Пользователь создан',
            intent: Intent.SUCCESS,
            icon: 'tick',
        });
    });

    useCanNav(props.usersActions.user_update.success, () => {
        AppToaster.show({
            message: 'Пользователь обновлен',
            intent: Intent.PRIMARY,
            icon: 'tick',
        });
    });

    useCanNav(props.usersActions.user_remove.success, () => {
        AppToaster.show({
            message: 'Пользователь удален',
            intent: Intent.PRIMARY,
            icon: 'trash',
        });
    });

    useCanNav(props.pricesActions.price_create.success, () => {
        AppToaster.show({
            message: 'Цена создана',
            intent: Intent.SUCCESS,
            icon: 'tick',
        });
    });

    useCanNav(props.pricesActions.price_update.success, () => {
        AppToaster.show({
            message: 'Цена обновлена',
            intent: Intent.PRIMARY,
            icon: 'tick',
        });
    });

    useCanNav(props.pricesActions.price_remove.success, () => {
        AppToaster.show({
            message: 'Цена удалена',
            intent: Intent.PRIMARY,
            icon: 'trash',
        });
    });

    useCanNav(props.configActions.config_update.success, () => {
        AppToaster.show({
            message: 'Настройки сохранены',
            intent: Intent.SUCCESS,
            icon: 'tick',
        });
    });
};
