import React from 'react';
import { Button, Intent } from '@blueprintjs/core';

import * as styles from './index.module.scss';

import { Pag } from '@resources/pagination/_pag';

type Props = {
    pag: Pag;
    onPage: (page: number) => void;
    isLoading?: boolean;
};

export const Pagination = (props: Props) => {
    let pagesCount = Math.ceil(props.pag.count / props.pag.limit);

    if (!pagesCount) {
        pagesCount = 1;
    }

    return (
        <div className={styles.container}>
            <Button
                // small={true}
                disabled={props.pag.page === 0}
                intent={Intent.NONE}
                icon="arrow-left"
                onClick={() => {
                    if (props.pag.page > 0) {
                        props.onPage(0);
                    }
                }}>
                На начало
            </Button>
            <Button
                // small={true}
                disabled={props.pag.page === 0}
                intent={Intent.NONE}
                icon="arrow-left"
                onClick={() => {
                    if (props.pag.page > 0) {
                        props.onPage(props.pag.page - 1);
                    }
                }}>
                Назад
            </Button>
            <div className={styles.desc}>
                страница {props.pag.page + 1} из {pagesCount}
            </div>
            <Button
                // small={true}
                disabled={pagesCount === props.pag.page + 1}
                intent={Intent.NONE}
                rightIcon="arrow-right"
                onClick={() => {
                    if (props.pag.page < pagesCount - 1) {
                        props.onPage(props.pag.page + 1);
                    }
                }}>
                Вперед
            </Button>
        </div>
    );
};
