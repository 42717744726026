import React from 'react';
import { FileInput, Intent } from '@blueprintjs/core';

import { useFilesUploader } from './hooks';
import { AppToaster } from '@components/ui/toaster';

type Props = {
    label?: string;
    onUploaded: (filenames: string[]) => void;
};

export const FilesUploader = (props: Props) => {
    const { loading, upload, fileInputRef } = useFilesUploader({
        onComplete: props.onUploaded,
        onError: (e) => {
            console.error(e);
            AppToaster.show({
                message: 'Ошибка при загрузке файлов: ' + e.message,
                intent: Intent.DANGER,
            });
        },
    });

    return (
        <FileInput
            text={loading ? 'Загрузка...' : props.label || 'Выберите файлы...'}
            disabled={loading}
            onInputChange={(e) => {
                upload();
            }}
            fill={true}
            buttonText="Выбрать"
            inputProps={{
                ref: fileInputRef,
                multiple: false,
                accept: '.jpg, .png, .jpeg',
            }}
        />
    );
};
