import React from 'react';
import { Button, FormGroup, InputGroup } from '@blueprintjs/core';
import { Flex } from 'reflexbox';

import * as styles from './index.module.scss';

import { Office } from '@resources/offices/_office';

type Props = {
    office: Office;
    onRemove: () => void;
    onUpdate: (office: Office) => void;
};

export const OfficeItem = (props: Props) => {
    const [office, setOffice] = React.useState<Office>(props.office);

    return (
        <div className={styles.container}>
            <div className={styles.number}>ID: {props.office.id}</div>
            <Flex width={['100%']}>
                <FormGroup label="Название" style={{ marginRight: 10 }}>
                    <InputGroup
                        value={office.name}
                        placeholder="Адрес"
                        onChange={(e) =>
                            setOffice({
                                ...office,
                                name: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <div className={styles.buttons}>
                    <Button
                        icon="floppy-disk"
                        style={{ marginRight: 10 }}
                        onClick={() => props.onUpdate(office)}>
                        Сохранить
                    </Button>
                    <Button icon="trash" onClick={props.onRemove}>
                        Удалить
                    </Button>
                </div>
            </Flex>
        </div>
    );
};
